import { Button, Grid, Text } from '@lemonenergy/lemonpie'
import type { BaseSyntheticEvent } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import Section from '~/components/Section'
import Subtitle from '~/components/Subtitle'
import Title from '~/components/Title'
import { gtag } from '~/utils/gtagEvents'

const TitleCol = styled(Grid.Col)(
  ({ theme: { spacing } }) => css`
    text-align: center;
    gap: ${spacing.md};
    margin-bottom: ${spacing(9)};
  `,
)

const StepCol = styled(Grid.Col).attrs({ sm: 6, lg: 3 })(
  ({ theme: { spacing } }) => css`
    gap: ${spacing.md};
  `,
)

const StepRow = styled(Grid.Row)(
  ({ theme: { media, spacing } }) => css`
    gap: ${spacing(9)};

    ${media.sm} {
      gap: 0;
      row-gap: ${spacing.md};
    }

    ${media.lg} {
      gap: 0;
    }
  `,
)

const Tag = styled(Text).attrs({
  variant: 'body200',
  bold: true,
  forwardedAs: 'span',
})(
  ({ theme: { spacing, colors } }) => css`
    flex: 0 0 auto;

    align-self: flex-start;
    padding: ${spacing(0.25)};
    background-color: ${colors.secondary.light};
  `,
)

const CallToAction = styled(Button)(
  () => css`
    max-width: 312px;
  `,
)

const ChildrenContainer = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${spacing(14)};
    gap: ${spacing.lg};
    max-width: 312px;
    width: 100%;
    align-items: center;
  `,
)

const SustainableEnergy: React.FC<{
  children?: React.ReactNode
  className?: string
  ctaHref?: string
}> = ({ children, className, ctaHref }) => {
  const trackCTAClick = (e: BaseSyntheticEvent) =>
    gtag(
      {
        event: 'click_interaction',
        source_id: 'sustainable-energy-button',
        interaction_type: 'button',
        label:
          'Botão da seção de energia sustentável que direciona o usuário para o formulário',
      },
      e,
    )

  return (
    <Section
      id="simplificamos-o-acesso-a-energia-sustentavel"
      className={className}
    >
      <Grid.Container>
        <Grid.Row>
          <TitleCol>
            <Title>
              simplificamos tudo para que você tenha acesso à energia limpa
            </Title>
            <Subtitle>
              Saiba como é fácil ter a Lemon como parceira do seu negócio.
            </Subtitle>
          </TitleCol>
        </Grid.Row>
        <StepRow>
          <StepCol>
            <Tag>Passo 1</Tag>
            <Text variant="title300" bold>
              Cadastre-se
            </Text>
            <Text>
              É só preencher as informações sobre seu negócio aqui no site e nós
              vamos calcular quanto de energia limpa você precisa gerar
              mensalmente com a usina parceira.
            </Text>
          </StepCol>
          <StepCol>
            <Tag>Passo 2</Tag>
            <Text variant="title300" bold>
              Se associe online
            </Text>
            <Text>
              Todo o é processo digital, sem nenhuma burocracia. Ah, nosso
              atendimento fica disponível a todo momento pra te ajudar no que
              precisar!
            </Text>
          </StepCol>
          <StepCol>
            <Tag>Passo 3</Tag>
            <Text variant="title300" bold>
              Gerar energia
            </Text>
            <Text>
              Após o envio da documentação, a gente cuida de tudo com a
              distribuidora e com a usina parceira para você gerar energia limpa
              de forma digital. Nada de obras ou investimentos.
            </Text>
          </StepCol>
          <StepCol>
            <Tag>Passo 4</Tag>
            <Text variant="title300" bold>
              Economize
            </Text>
            <Text>
              Você recebe a sua conta Lemon com os valores da energia limpa
              gerada no mês e para a distribuidora você paga apenas tarifas e
              impostos.
            </Text>
          </StepCol>
        </StepRow>
      </Grid.Container>
      <ChildrenContainer>
        <CallToAction onClick={trackCTAClick} to={ctaHref}>
          Quero economizar
        </CallToAction>
        {children}
      </ChildrenContainer>
    </Section>
  )
}

export default SustainableEnergy
