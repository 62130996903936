import { Icon, Text } from '@lemonenergy/lemonpie'
import React from 'react'
import styled, { css } from 'styled-components'

const ListItem = styled.li(
  ({ theme: { colors, spacing } }) => css`
    display: flex;
    gap: ${spacing.xs};
    align-items: center;

    > ${Icon} {
      color: ${colors.primary.main};
    }
  `,
)

const StateListItemRef: React.FC<{
  children?: React.ReactNode
  className?: string
}> = ({ children, className }) => {
  return (
    <ListItem className={className}>
      <Icon name="shine" size={{ xs: 'sm', lg: 3 }} />
      <Text variant={{ xs: 'body100', lg: 'title300' }} bold>
        {children}
      </Text>
    </ListItem>
  )
}

export const StateListItem = styled(StateListItemRef)``

export const StateList = styled.ul(
  ({ theme: { spacing } }) => css`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing.sm};

    ${StateListItem} {
      flex: 1 0 calc(50% - ${spacing.sm});
    }
  `,
)
